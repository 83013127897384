import * as React from "react"
import {useState, useEffect} from "react"
import i18n from '../../components/i18n'
import { withTranslation, useTranslation } from 'react-i18next';
import Layout from "../../components/layout"
import ServiceSingleBoxCard from "../../components/service_single_box_card"
import HubspotCta from "../../components/hubspot_cta"

import { ServiceContactForm } from "../../components/form_service_contact"
import SocialConfData from "../../conf/social.json"
import Seo from '../../components/seo'
import ClientSlider  from "../../components/client_slider"

// Images import
import img_service_header from "../../images/service-header.jpg"
import img_service_details from "../../images/service_cloud_roadmap.png"

// Testimonial
import NavigationHelper from "../../helpers/navigation";


// markup
const SolutionPage = (props) => {
  
  const [activeLanguage, setActiveLanguage] = useState()
  useEffect(() => { setActiveLanguage(i18n.language); }, [setActiveLanguage])

  const { t } = useTranslation(['main', 'forms']);
  const ExtContactForm = withTranslation('forms')(ServiceContactForm)
  const LocalizedClientSlider = withTranslation('main')(ClientSlider)

  // Navigation info
  const pageInfo = {
    key: "fr-solutions", // This allows the langswitcher to add a JS link instead of a regular one
    // item_key: "",  // This allows the langswitcher to add a JS link instead of a regular one
    lang: i18n.language
  }

  //For the social share buttons
  const title = t('main:service-description_cloud-roadmap_meta_page_title' )
  const url = props.location.href;
  const twitterHandle = SocialConfData.ids.twitter;
  const tags = [
    t('main:service-description_cloud-roadmap_social_twitter_tag1_txt'),
    t('main:service-description_cloud-roadmap_social_twitter_tag2_txt'),
    t('main:service-description_cloud-roadmap_social_twitter_tag3_txt'),
    t('main:service-description_cloud-roadmap_social_twitter_tag4_txt')
  ];

  return (
    <Layout
      location={props.location}
      pageInfo={pageInfo}
    >

      <Seo
        description={t('main:service-description_cloud-roadmap_meta_seo_description_txt')}
        title={t('main:service-description_cloud-roadmap_meta_page_title')}
        keywords={[
          t('main:service-description_cloud-roadmap_meta_seo_keyword1_txt'),
          t('main:service-description_cloud-roadmap_meta_seo_keyword2_txt'),
          t('main:service-description_cloud-roadmap_meta_seo_keyword3_txt'),
          t('main:service-description_cloud-roadmap_meta_seo_keyword4_txt'),
        ]}
        url={props.location.href}
        image_url={`${props.location.origin}/a1_cloud_services_in_a_box.webp`}
      />

    {/* breadcumbs */}
    <div className="site-breadcumbs">
      <div className="container">
        <small>
          <a href={NavigationHelper.useGeneratePageURL(activeLanguage, 'home')} title={t('main:global_header_home_menu-item-seo-title_txt')}>
            A1:
          </a>{" "}
          // <a href={NavigationHelper.useGeneratePageURL(activeLanguage, 'services')} title={t('main:global_header_services_menu-item-seo-title_txt')}>{t('main:services_header_breadcrumb_title')}</a> / <a  href={NavigationHelper.useGeneratePageURL(activeLanguage, 'services')}>{t('main:services_header_audit-category_breadcrumb_title')}</a> / {t('main:service-description_cloud-roadmap_header_breadcrumb_title')}
        </small>
      </div>
    </div>
    {/* end breadcumbs */}

    
    <div
    className="s_detail service_header"
    style={{ backgroundImage: `url(${img_service_header})` }}
  >
    <div className="container">
      <div className="title_block">
        <h1 className="nos-service">
          <span> {t('main:services_header_cat-audit-block_title' )} :</span> {t('main:service-description_cloud-roadmap_header_title' )}
        </h1>
      </div>
      <div className="row">
        <div className="col-md-5">
          <div className="book_img">
            <img
              className="book_img"
              src={img_service_details}
              alt="Books"
            />
          </div>
        </div>
        <div className="col-md-7">
          <div className="block_text desktop_view">
            <h2 className="h2_text">
            {/* <Trans i18nKey="main:service-description_cloud-roadmap_tagline_html"> */}
            <strong>Comment atteindre</strong><br/>
            <strong>vos objectifs ?</strong>
            {/* </Trans> */}
            </h2>
            <div className="p_text">
            {/* <Trans i18nKey="main:service-description_cloud-roadmap_tagline-details_html"> */}
              <p>
              Découvrez comment organiser et structurer votre infrastructure Cloud pour atteindre vos objectifs.<br/>
              Trouver l'approche et les technologies qui sont vraiment pertinentes et appropriées dans votre situation.
              </p>
            {/* </Trans> */}
            </div>
          </div>
          <div className="block_text mobile_view">
            <h2 className="h2_text">
            {/* <Trans i18nKey="main:service-description_cloud-roadmap_tagline_html"> */}
            <strong>Comment atteindre</strong>
            <strong>vos objectifs ?</strong>
            {/* </Trans> */}
            </h2>
            <div className="p_text">
            {/* <Trans i18nKey="main:service-description_cloud-roadmap_tagline-details_html"> */}
              <p>
              Découvrez comment organiser et structurer votre infrastructure Cloud pour atteindre vos objectifs.<br/>
              Trouver l'approche et les technologies qui sont vraiment pertinentes et appropriées dans votre situation.
              </p>
            {/* </Trans> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* service single header end  */}
  {/* middle section  */}
  <div className="service_detail s_detail">
    <div className="container">
      <div className="block_box">
      <ServiceSingleBoxCard
            isNew={false}
            category="audits"
            category_label={t('main:services_main_audit-category_title' )}
            service_name={t('main:services_main_cloud-roadmap-offer_title' )}
            service_name_fragment_1={t('main:services_main_cloud-roadmap-offer_p1')}
            service_name_fragment_2={t('main:services_main_cloud-roadmap-offer_p2')}
            link_seo_title={t('main:global_main_know-more_link')}
            share_label={t('main:global_main_share_label')}
            service_description={t('main:service-description_cloud-roadmap_left-block_desc_txt')}

            share_title={title}
            share_url={url}
            share_twitter_handle={twitterHandle}
            share_twitter_tags={tags}
          />
        
      </div>
      <div className="middle_content">
        <h2>
          <span>{t('main:service-description_main_audit-category_title' )}</span> {t('main:service-description_cloud-roadmap_title')}
        </h2>
        {/* <Trans i18nKey="main:service-description_cloud-roadmap_intro_html"> */}
        <p>
        Les sujets liés au Cloud et à l'infrastructure ont une influence capitale sur l'ensemble de votre organisation technique.
        </p>
        <p>
        Les décisions à prendre en la matière ont un impact important sur votre entreprise et ses résultats.
        </p>
        <p>
        Prendre assez de recul pour voir la situation dans sa globalité tout en ayant conscience des détails d'implémentation, des contraintes actuelles et à venir n'est pas chose simple.
        </p>
        {/* </Trans> */}
        <h4>{t('main:service-description_main_solution-section_title')}</h4>
        {/* <Trans i18nKey="main:service-description_cloud-roadmap_solution_html"> */}
        <p>
        Le point de vue d'experts en la matière, capables de prendre en compte vos besoins, vos contraintes et votre contexte vous apporte la clarté nécessaire à vos prises de décisions.
        </p>
        <p>
        Ensemble, nous définissons les contours de l'étude dont vous avez besoin.
        </p>
        <p>
        Ces audits se focaliseront sur vos priorités pour répondre à vos interrogations tout en analysant en profondeur votre infrastructure et son fonctionnement.
        </p>
        {/* </Trans> */}
        <h4>{t('main:service-description_main_benefits-section_title')}</h4>
        {/* <Trans i18nKey="main:service-description_cloud-roadmap_benefits_html"> */}
        <p>
        Le programme Cloud Roadmap est façonné selon vos priorités et vos spécificités.<br/>Les bénéfices que vous pourrez en retirer dépendent de vos besoins.
        </p>
        <p>
        Selon le focus que vous en attendrez, vous obtiendrez des bénéfices dans les domaines suivants :
        </p>
        <p>
          <ul>
            <li><strong>.</strong>la réduction des risques</li>
            <li><strong>.</strong>l'optimisation des coûts</li>
            <li><strong>.</strong>la mise en conformité</li>
            <li><strong>.</strong>l'amélioration des performances et de la productivité</li>
          </ul>
        </p>
        <p>
        Contactez-nous dès aujourd'hui pour planifier une consultation initiale, sans engagment, au cours de laquelle nous étudierons ensemble les avantages les plus pertinents et importants à poursuivre dans votre situation.
        </p>
        {/* </Trans> */}
        
        <div className="button-banner">
        <HubspotCta 
            title={t('main:service-description_dtaas_cta_-tooltip_txt')}
            btnTxt={t('main:service-description_dtaas_cta_link')}
            ctaId="b3e6a794-c4e2-45c6-a1fa-9d7b680e85dc"
            url="https://hubspot-cta-redirect-eu1-prod.s3.amazonaws.com/cta/redirect/26582871/a10c4452-6fd6-4ae8-bb31-f89bedbfc14e"
        />
      </div>

      </div>
      <div className="form_block" id="contact-form">
      <h3>
          {/* <Trans i18nKey="main:service-description_cloud-roadmap_form_header_html"> */}
          Rencontrons-nous et concevons ensemble l'étude qui correspond à vos priorités.
          {/* </Trans> */}
      </h3>
      <ExtContactForm
        contact_source={`${t('main:service-description_cloud-roadmap_meta_page_title')}`}
        name_label={t('forms:home_contact_name_label' )}
        name_ph={t('forms:home_contact_name_placeholder' )}
        fname_label={t('forms:home_contact_firstname_label' )}
        fname_ph={t('forms:home_contact_firstname_placeholder' )}
        company_label={t('forms:home_contact_company_label' )}
        company_ph={t('forms:home_contact_company_placeholder' )}
        phone_label={t('forms:home_contact_phone_label' )}
        phone_ph={t('forms:home_contact_phone_placeholder' )}
        email_label={t('forms:home_contact_email_label' )}
        email_ph={t('forms:home_contact_email_placeholder' )}
        message_label={t('forms:home_contact_message_label' )}
        message_ph={t('forms:services_contact_message_placeholder' )}
        terms_msg_p1={t('forms:home_contact_terms_p1' )}
        terms_msg_p2={t('forms:home_contact_terms_p2' )}
        send_btn={t('forms:home_contact_send_btn')}

        notif_at_least_one_error_msg={t('forms:global_error_at_least_one_msg')}
        notif_sending_form_msg={t('forms:global_info_sending_form_msg')}
        success_message_sent_msg={t('forms:global_success_message_sent_msg')}
        notif_unknown_error_msg={t('forms:global_error_unknown_msg')}
        notif_email_validation_error_msg={t('forms:notif_email_validation_error_msg')}
        notif_lname_validation_error_msg={t('forms:notif_lname_validation_error_msg')}
        notif_fname_validation_error_msg={t('forms:notif_fname_validation_error_msg')}
        notif_company_validation_error_msg={t('forms:notif_company_validation_error_msg')}
        notif_number_validation_error_msg={t('forms:notif_number_validation_error_msg')}
        notif_message_validation_error_msg={t('forms:notif_message_validation_error_msg')}

        form_required_field_msg={t('forms:global_label_required_field_msg')}
      />
      </div>
    </div>
  </div>
  {/* end middle section */}

  <div className="services-reference" id="clients">
      <LocalizedClientSlider />
    </div>
  
  {/* main content end */}
    </Layout>
  )
}

export default SolutionPage
